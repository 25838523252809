.vennDiagramWidget {
  width: 350px;
  height: 200px;
  margin: 0 auto;
  background-color: #f3e7e7;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 13px;
}
.vennDiagramChart {
  z-index: 99999;
  svg text {
    fill: #784e4e;
    font-size: 14px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  [data-venn-sets='A'] {
    fill: #ffbc02;
  }
  [data-venn-sets='B'] {
    fill: #ff5d5a;
  }
  [data-venn-sets='A_B'] {
    fill: #ff8533;
    color: white;
    svg text {
      fill: white;
    }
  }
  [data-venn-sets='A_B'] .label {
    fill: white;
  }
}

.titleUnderCircle {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #784e4e;
}

.titlesWrapper {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 14px;
  min-height: 35px;
}
.diagramTitleContainer {
  margin-top: -23%;
}
.bottomContent {
  border-top: 1px solid white;
  text-align: center;
  width: 100%;
  padding: 12px 0;
  color: #784e4e;
  line-height: 19px;
  font-size: 15px;
  font-weight: 700;
}
